
import {
    Edit,
    TextInput,
    DateInput,
    NumberInput,
    BooleanInput,
    ReferenceInput,
    ReferenceField,
    TextField,
    SelectInput,
    ArrayInput,
    ArrayField,
    Datagrid,
    SimpleFormIterator,
    AutocompleteInput,
    FormWithRedirect,
    SaveButton,
    DeleteButton,
    useGetList,
    Loading,
    Error,
    required,
} from 'react-admin';
import { Box, Toolbar } from '@material-ui/core';
import OrderCloneButton from './OrderCloneButton';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';

const useStyles = makeStyles({
    root: {
        maxWidth: '160px',
        padding: '0'
    },
    input: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px 4px 0px 2px;',
    },
    container: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px 4px 0px 2px;',
        display: 'inline',
        float: 'left',
        '& div': {
            flexWrap: 'wrap',
            marginTop: '0px'
        },
    },
    inlineInput: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px 4px 0px 2px;',
        display: 'inline',
        float: 'left'
    },
    toolbarButton: {
        marginRight: '5px',
        marginTop: '0px',
        marginBottom: '0px'
    },
    inputQuantity: {
        width: '80px',
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px 4px 0px 2px;',
        display: 'inline',
        float: 'left',
    },
    precision: {
        marginTop: '0px',
        width: '200px',
        padding: '0',
        display: 'inline',
        float: 'left',
        root: {
            marginTop: '0px',
            width: '200px',
            padding: '0',
            display: 'inline',
            float: 'left',
        },
        input: {
            marginTop: '0px',
            marginBottom: '0px',
            padding: '0px 4px 0px 2px;',
            display: 'inline',
            float: 'left',
        },
        select: {
            marginTop: '0px',
            marginBottom: '0px',
            padding: '0px 4px 0px 2px;',
            display: 'inline',
            float: 'left',
        },
    },
    inputEarlyDelivery: {
        order: 2,
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px 4px 0px 2px;',
        display: 'inline',
        float: 'left'
    },
    inputDeliveryLocation: {
        root: {
            width: '100%',
            padding: '0'
        },
        input: {
            width: '100%',
            marginTop: '0px',
            marginBottom: '0px',
            padding: '0px 4px 0px 2px;',
        },
        container: {
            width: '100%',
            marginTop: '0px',
            marginBottom: '0px',
            padding: '0px 4px 0px 2px;',
            '& div': {
                flexWrap: 'wrap',
                marginTop: '0px'
            },
        },
    }
});

const rateFormatter = value => {
    console.log('rateFormatter', value);
    if (value == null || value === '$' || value === '') return '$';
    if (isNaN(value)) return;
    const d = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value);
    return d;
};

const rateParser = value => {
    console.log('rateParser', value);
    return value.replace("$", "");
};

function compare( a, b ) {
  if (a.Delivery_Location < b.Delivery_Location) {
    return -1;
  }
  if (a.Delivery_Location > b.Delivery_Location) {
    return 1;
  }
  return 0;
}

const DeliveryLocationInput = props => {
    const { values } = useFormState();
    const { data, ids, loading, error } = useGetList(
        'deliverylocations',
        { page: 1, perPage: 400 },
        { field: 'Delivery_Location', order: 'ASC' },
        { cid: values.CustomerID }
    );
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    const objs = Object.values(data);
    return (
        <SelectInput
            choices={objs.length > 0 ? objs.sort(compare) : []}
            {...props}
        />
    );
};

const OrderEditForm = props => {
    const classes = useStyles();

    return (
        <FormWithRedirect
            {...props}
            render={formProps => (
                <form>
                    <Toolbar>
                        <Box display="flex" justifyContent="flex-end" width="100%">
                            <SaveButton
                                variant="outlined"
                                label="Generate PDFs"
                                saving={formProps.saving}
                                redirect="show"
                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                className={classes.toolbarButton}
                            />
                            <OrderCloneButton
                                variant="outlined"
                                record={formProps.record}
                                className={classes.toolbarButton}
                            />
                            <SaveButton
                                redirect="show"
                                saving={formProps.saving}
                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                className={classes.toolbarButton}
                            />
                        </Box>
                    </Toolbar>
                    <Box p="1em">
                        <Box display="flex">
                            <Box flexGrow={1} m="0" p="0">
                                <DateInput label="Order Date" source="Order_Date" validate={[required()]} className={classes.input} />
                                <TextInput label="Order Number" source="Order_Num" validate={[required()]} className={classes.input} />
                                <SelectInput label="Type" source="Order_Type" choices={[
                                    { id: 'Packaged', name: 'Packaged' },
                                    { id: 'Bulk', name: 'Bulk' },
                                ]} validate={[required()]} className={classes.input} />
                                <ReferenceInput
                                    classes={classes}
                                    label="Customer"
                                    source="CustomerID"
                                    reference="customers"
                                    sort={{ field: 'Customer', order: 'ASC' }}
                                    allowEmpty >
                                    <AutocompleteInput
                                        optionText="Customer"
                                    />
                                </ReferenceInput>
                                <TextInput label="PO Number" source="PO_Num" className={classes.input} />
                            </Box>
                        </Box>
                        <ArrayInput label="Order Details" source="OrderDetails" >
                            <SimpleFormIterator>
                                <NumberInput label="Quantity" source="Quantity" className={classes.inputQuantity} />
                                <ReferenceInput
                                    classes={classes}
                                    label="Precision"
                                    source="PrecisionID"
                                    reference="precisions"
                                    allowEmpty >
                                    <AutocompleteInput optionText="Precision" />
                                </ReferenceInput>
                                <ReferenceInput
                                    classes={classes}
                                    label="Product"
                                    source="ProductID"
                                    reference="products"
                                    sort={{ field: 'Product', order: 'ASC' }}
                                    allowEmpty >
                                    <AutocompleteInput optionText="Product" />
                                </ReferenceInput>
                                <ReferenceInput
                                    classes={classes}
                                    label="Pick-Up Location"
                                    source="PickupLocationID"
                                    reference="pickuplocations"
                                    sort={{ field: 'Pickup_Location', order: 'ASC' }}
                                    allowEmpty >
                                    <AutocompleteInput optionText="Pickup_Location" />
                                </ReferenceInput>
                                <TextInput label="Release #" source="Release_Num" validate={[required()]} className={classes.input} />
                                <DeliveryLocationInput
                                    classes={classes}
                                    source="DeliveryLocationID"
                                    validate={[required()]}
                                    optionText="Delivery_Location"
                                    label="Delivery Location"
                                    fullWidth
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <Box display="flex">
                            <Box flexGrow={1} m="0" p="0" flexWrap="nowrap" justifyContent="flex-start" flexDirection="row">
                                <ReferenceInput
                                    classes={classes}
                                    label="Trucking Company"
                                    source="CarrierID"
                                    reference="truckingcompanies"
                                    sort={{ field: 'Trucking_Company', order: 'ASC' }}
                                    perPage={200}
                                    allowEmpty>
                                    <AutocompleteInput optionText="Trucking_Company" />
                                </ReferenceInput>
                                <SelectInput
                                    className={classes.input}
                                    allowEmpty
                                    label="Truck Type"
                                    source="Truck_Type"
                                    choices={[
                                        { id: 'Doubles', name: 'Doubles' },
                                        { id: 'End-Dump', name: 'End-Dump' },
                                        { id: 'Flatbed', name: 'Flatbed' },
                                        { id: 'Lift-Gate', name: 'Lift-Gate' },
                                        { id: 'Roll-Off', name: 'Roll-Off' },
                                        { id: 'Transfers', name: 'Transfers' },
                                        { id: 'Van', name: 'Van' },
                                        { id: 'Walking Floor', name: 'Walking Floor' },
                                    ]}
                                />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box flexGrow={1} m="0" p="0">
                                <Box m="0" p="0">
                                    <DateInput label="Pick-Up Date" source="PickUp_Date" validate={[required()]} className={classes.input} />
                                    <DateInput label="Delivery Date" source="Delivery_Date" validate={[required()]} className={classes.input} />
                                    <TextInput label="Freight Charge" source="Trucking_Charge" className={classes.input} format={rateFormatter} parse={rateParser} />
                                </Box>
                                <Box m="0" p="0">
                                    <BooleanInput label="Early Delivery" source="Early_Delivery" className={classes.inputEarlyDelivery} />
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box flexGrow={1} m="0" p="0">
                                <Box m="0" p="0">
                                    <TextInput multiline label="Special Instructions" source="Special_Instructions" className={classes.input}/>
                                    <TextInput multiline label="Billing Notes" source="Billing_Notes" className={classes.input} />
                                </Box>
                                <Box m="0" p="0">
                                    <BooleanInput label="Pending" source="Pending" className={classes.inputEarlyDelivery} />
                                    <BooleanInput label="Emailed Trucking Manifest" source="Emailed_Trucking_Manifest" className={classes.inputEarlyDelivery} />
                                    <BooleanInput label="Emailed PickUp Notice" source="Emailed_PickUp_Notice" className={classes.inputEarlyDelivery} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Toolbar>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <SaveButton
                                redirect="show"
                                saving={formProps.saving}
                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            />
                            <DeleteButton record={formProps.record} />
                        </Box>
                    </Toolbar>
                    <Box flexGrow={1} m="20" p="0">
                        <ArrayField source="OrderDetails">
                            <Datagrid>
                                <ReferenceField label="Edit Delivery Location" source="DeliveryLocationID" reference="deliverylocations" sortable={false} link={true}>
                                    <TextField source="Delivery_Location" />
                                </ReferenceField>
                            </Datagrid>
                        </ArrayField>
                    </Box>
                </form>
            )}
        />
    )
}

export const OrderEdit = props => (
    <Edit {...props}>
        <OrderEditForm />
    </Edit>
);

export default OrderEdit;
